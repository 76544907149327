<template>
  <app-grid-col
    :md="5"
    :md-start="1"
    class="positions-create-locale"
  >
    <section class="positions-create-locale__container">
      <app-text-input
        class="positions-create-locale__field"
        name="locale"
        v-model="locale"
        :placeholder="$t(`${langPath}.placeholder`)"
        :label="$t(`${langPath}.label`)"
        google-location
        :data-vv-as="$t(`${langPath}.label`)"
        v-validate="localeValidation"
        data-vv-validate-on="blur"
        :disabled="localeRemote"
        @place="setPlace"
        @input="clearPlace"
        data-test="position-locale-input"
        data-cy="position-locale-input"
      />

      <app-checkbox
        class="positions-create-locale__field-remote"
        :name="'locale-remote'"
        :checked="localeRemote"
        v-model="localeRemote"
        :label="$t(`${langPath}.localeRemote.label`)"
        @input="clearInputLocale"
      />
    </section>
  </app-grid-col>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { COMPANIES_POSITIONS } from '@/store/namespaces'

const { mapState, mapActions } = createNamespacedHelpers(COMPANIES_POSITIONS)

export default {
  name: 'PositionsCreateLocale',
  data () {
    return {
      langPath: __langpath,
      locale: '',
      localeRemote: false,
      placeId: null
    }
  },
  computed: {
    ...mapState(['positionForm']),
    localeValidation () {
      return {
        required: !this.localeRemote,
        location: this.placeId
      }
    }
  },
  watch: {
    locale (newValue) {
      const attributes = {
        name: 'locale',
        value: newValue
      }

      this.setPositionFormAttribute(attributes)
    },
    localeRemote (newValue) {
      const attributes = {
        name: 'localeRemote',
        value: newValue
      }

      this.setPositionFormAttribute(attributes)
    },
    positionForm (newValue, _oldValue) {
      this.locale = newValue.locale
      this.localeRemote = newValue.localeRemote
    }
  },
  created () {
    if (this.$route.params.positionId) {
      this.placeId = 'given'
    }
  },
  methods: {
    ...mapActions(['setPositionFormAttribute']),
    setPlace (place) {
      this.locale = place.formatted_address
      this.placeId = place.place_id
      this.setPositionFormAttribute({
        name: 'googlePlacesId',
        value: place.place_id
      })
    },
    clearPlace () {
      this.placeId = null
      this.setPositionFormAttribute({
        name: 'googlePlacesId',
        value: undefined
      })
    },
    clearInputLocale () {
      this.placeId = null
      this.locale = ''
      this.error = null
    }
  }
}
</script>

<style lang="scss" scoped>
.positions-create-locale {
  &__container {
    display: flex;
    flex-direction: column;
  }

  &__field {
    flex: 1;
  }

  &__field-remote {
    align-self: self-start;
    display: block;
    flex: 1;
    padding: $base 0;
  }
}
</style>
