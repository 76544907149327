<template>
  <app-grid-col
    class="positions-create-skills"
    :md="10"
    :md-start="1"
  >
    <label class="label">
      {{ $t(`${langPath}.label`) }}
    </label>
    <app-tags-selector
      name="skills"
      v-model="skills"
      v-validate="{ 'required': isRequired }"
      :options="suggestedSkills"
      :autocomplete-placeholder="$t(`${langPath}.placeholder`)"
      :options-title="$t(`${langPath}.helper`)"
      :required="isRequired"
      :search-function="getSkillsFunctions"
      :data-vv-as="$t(`${langPath}.label`)"
      data-vv-validate-on="blur"
      open-scope
      data-test="position-skills-input"
      :item-name="$t(`${langPath}.tip`)"
    />
  </app-grid-col>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { COMPANIES_POSITIONS } from '@/store/namespaces'
import autocomplete from '@/repository/companies-app/autocomplete'
import suggestions from '@/repository/companies-app/suggestions'

const { mapState, mapActions } = createNamespacedHelpers(COMPANIES_POSITIONS)

export default {
  name: 'PositionsCreateSkills',
  data () {
    return {
      langPath: __langpath,
      skills: [],
      getSkillsFunctions: (name) => autocomplete
        .getSkills(name)
        .then((skills) => skills
          .map((skill) => ({ value: skill.id, label: skill.text }))
        ),
      suggestedSkills: []
    }
  },
  computed: {
    ...mapState(['positionForm']),
    isRequired () { return this.positionForm.positionId !== 'others' }
  },
  watch: {
    skills (newValue) {
      const attributes = {
        name: 'skills',
        value: newValue
      }

      this.setPositionFormAttribute(attributes)
    },
    async positionForm (newValue, oldValue) {
      this.skills = newValue.skills
      if (newValue.positionId === oldValue.positionId) return
      if (newValue.positionId && newValue.positionId !== 'others') {
        this.suggestedSkills = (
          await suggestions.getSkills(newValue.positionId)
        ).map((skill) => ({ value: skill.id, label: skill.name }))
      } else {
        this.suggestedSkills = []
      }
    }
  },
  methods: {
    ...mapActions(['setPositionFormAttribute'])
  }
}
</script>
<style lang="scss">
.positions-create-skills {
  z-index: 20;
}
</style>
