<template>
  <app-grid-col
    :md="5"
    :md-start="1"
    class="positions-create-experience"
  >
    <label
      class="label positions-create-experience__optional-label"
      data-test="positions-create-experience"
    >
      {{ $t(`${langPath}.label`) }}
      <span class="positions-create-experience__optional-flag">
        {{ $t(`${langPath}.optionalFlag`) }}
      </span>
    </label>

    <app-select
      name="experienceTime"
      :options="experienceTimeOptions"
      v-model="experienceTime"
      class="input-wrapper"
      :required="true"
      v-validate="'required'"
      :data-vv-as="$t(`${langPath}.label`)"
      data-test="position-experience-time-select"
    />
  </app-grid-col>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { COMPANIES_POSITIONS } from '@/store/namespaces'
import { experienceTimes } from '@/helpers/constants/experience-times'

const { mapState, mapActions } = createNamespacedHelpers(COMPANIES_POSITIONS)

export default {
  name: 'PositionsCreateExperienceTime',
  data () {
    return {
      langPath: __langpath,
      experienceTime: experienceTimes[0].value
    }
  },
  computed: {
    ...mapState(['positionForm']),
    experienceTimeOptions () {
      return experienceTimes.map((option) => ({
        value: option.value,
        label: this.$t(
          `${this.langPath}.options.${option.key}`
        )
      }))
    }
  },
  watch: {
    experienceTime (value) {
      const attributes = {
        name: 'experienceTime', value
      }
      this.setPositionFormAttribute(attributes)
    },
    positionForm (newValue, oldValue) {
      if (this.experienceTime === undefined &&
        newValue.experienceTime === undefined) {
        this.experienceTime = experienceTimes[0].value
      } else {
        this.experienceTime = newValue.experienceTime
      }
    }
  },
  methods: {
    ...mapActions(['setPositionFormAttribute'])
  }
}
</script>
<style lang="scss" scoped>
  .positions-create-experience {
    &__optional-label {
      display: flex;
      justify-content: space-between;
    }

    &__optional-flag {
      color: var(--tx-neutral-light-01);
      margin-left: auto;
      padding-right: 5px;

      @extend %caption;
    }
  }
</style>
