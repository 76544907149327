import { get } from '@/helpers/request'
import JSONAPIClient from '@/helpers/jsonapi-client'
import { companiesUrl } from '@/helpers/constants/external-urls'

const baseUrl = `${companiesUrl}/api/v2/suggestions`

export default {
  async getBenefits () {
    const response = await get(`${baseUrl}/benefits?quantity=4`)

    return JSONAPIClient.parse(response.data)
  },

  async getSkills (positionId) {
    const response = await get(`${baseUrl}/skills?position_id=${positionId}&quantity=4`)

    return JSONAPIClient.parse(response.data)
  },

  async getDiversities () {
    const response = await get(`${baseUrl}/diversities`)

    return JSONAPIClient.parse(response.data)
  }
}
