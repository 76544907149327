<template>
  <app-grid-col :md="3" :md-start="8">
    <section class="positions-create-submit">
      <ev-button
        :disabled="disableButton"
        data-test="positions-submit-button"
        @click="submit"
      >
        {{ submitButtonText }}
      </ev-button>
    </section>
  </app-grid-col>
</template>

<script>
import EvButton from '@revelotech/everestV2/EvButton'
import { createNamespacedHelpers } from 'vuex'
import { NOTIFICATION_CARDS, COMPANIES_POSITIONS } from '@/store/namespaces'
import { companiesUrl } from '@/helpers/constants/external-urls'

const { mapState, mapActions } = createNamespacedHelpers(COMPANIES_POSITIONS)
const notificationHelper = createNamespacedHelpers(NOTIFICATION_CARDS)

export default {
  name: 'PositionsCreateSubmit',
  components: {
    EvButton
  },
  data () {
    return {
      langPath: __langpath,
      disableButton: false
    }
  },
  computed: {
    ...mapState(['positionForm']),
    submitButtonText () {
      if (this.$route.params.positionId) {
        return this.$t(`${this.langPath}.submitUpdateButton`)
      } else {
        return this.$t(`${this.langPath}.submitCreateButton`)
      }
    }
  },
  methods: {
    ...mapActions(['createPosition', 'updatePosition', 'getPosition']),
    ...notificationHelper.mapActions(['createNotificationCard']),
    async submit () {
      if (!await this.$validator._base.validateAll()) return

      this.disableButton = true

      let response
      if (this.$route.params.positionId) {
        response = await this.updatePosition()
      } else {
        response = await this.createPosition()
      }

      if (response.errors) {
        this.disableButton = false
        if (response.errors.detail &&
          Object.values(response.errors.detail)
            .flat()
            .map((error) => error.error)
            .filter((error) => error === 'taken')
            .length
        ) {
          this.createNotificationCard({
            title: this.$t(`${this.langPath}.errorTakenTitle`),
            message: this.$t(`${this.langPath}.errorTakenMessage`),
            modifier: 'error'
          })
        } else {
          this.createNotificationCard({
            title: this.$t(`${this.langPath}.errorGenericTitle`),
            message: this.$t(`${this.langPath}.errorGeneric`),
            modifier: 'error'
          })
          this.$rollbar.error(response.errors.detail)
        }
      } else {
        if (typeof this.$route?.query?.redirect === 'string') {
          return window.location.assign(this.$route?.query?.redirect)
        }
        if (this.positionForm.positionId === 'others') {
          return this.finishOther(response)
        }

        this.finishTaxonomy(response)
      }
    },
    async finishTaxonomy (response) {
      await this.getPosition(response.id)
      if (response.links?.searchPageHref) {
        window.location.assign(`${companiesUrl}${response.links.searchPageHref}`)
      } else {
        this.$router.push({ name: 'Positions' })
      }
    },
    finishOther (response) {
      setTimeout(() => {
        const url = `${window.baseUrl}/#/external/publish/position/${response.id}?allowSkip=true`
        window.location.assign(url)
      }, 3000)
    }
  }
}
</script>
<style lang="scss">
  .positions-create-submit {
    display: flex;
    flex-flow: wrap;
    justify-content: flex-end;

    &__button {
      width: 112px;
    }
  }
</style>
