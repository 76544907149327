<template>
  <app-grid-col :md="5" :md-start="1" class="positions-create-career">
    <app-text-input
      name="positionTitle"
      :placeholder="$t(`${langPath}.placeholder`)"
      v-model="positionTitle"
      :label="$t(`${langPath}.label`)"
      :required="true"
      v-validate="'required'"
      :data-vv-as="$t(`${langPath}.label`)"
      data-vv-validate-on="blur"
      data-test="position-title-input"
      @blur="handleBlur"
    />
  </app-grid-col>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { COMPANIES_POSITIONS } from '@/store/namespaces'
import jobPosting from '@/repository/companies-app/job-posting'

const positionsHelper = createNamespacedHelpers(COMPANIES_POSITIONS)

export default {
  name: 'PositionsCreateTitle',
  data () {
    return {
      langPath: __langpath,
      positionTitle: ''
    }
  },
  computed: {
    ...positionsHelper.mapState(['positionForm'])
  },
  watch: {
    positionTitle (newValue) {
      const attributes = {
        name: 'positionTitle',
        value: newValue
      }
      this.setPositionFormAttribute(attributes)
    },
    positionForm (newValue, _oldValue) {
      this.positionTitle = newValue.positionTitle
    }
  },
  methods: {
    ...positionsHelper.mapActions(['setPositionFormAttribute']),
    async handleBlur () {
      const position = await jobPosting.matchPosition(this.positionTitle)
      if (!position) return

      this.setPositionFormAttribute({ name: 'positionId', value: position.id })
      this.setPositionFormAttribute({ name: 'positionName', value: position.name })
    }
  }
}
</script>
