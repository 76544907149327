<template>
  <app-grid-col :md="5" :md-start="1" class="positions-create-authorizations">
    <section class="private-position">
      <section class="private-position__field">
        <app-checkbox
          v-model="positionsPrivate"
          name="positionsPrivate"
          class="private-position__option"
          :label="''"
        />
      </section>
      <section class="private-position__label">
        <p class="private-position__title">
          {{ $t(`${langPath}.title`) }}

          <ev-icon
            name="lock-alt"
            color="gray20"
            size="mini"
          />
        </p>
        <p class="private-position__description">
          {{ $t(`${langPath}.description`) }}
        </p>
      </section>
    </section>
    <section
      v-if="positionsPrivate"
      class="private-employers"
    >
      <label class="private-employers__label">
        <span>{{ $t(`${langPath}.label`) }}</span>
        <span>{{ $t(`${langPath}.optionalFlag`) }}</span>
      </label>

      <app-tags-selector
        v-model="positionEmployers"
        name="employers"
        class="private-employers__field"
        data-test="position-employers-input"
        :options="[]"
        :autocomplete-placeholder="$t(`${langPath}.employerName`)"
        :search-function="findEmployers"
        :data-vv-as="$t(`${langPath}.employerName`)"
        data-vv-validate-on="blur"
        :item-name="$t(`${langPath}.employerName`)"
      />
    </section>
  </app-grid-col>
</template>

<script>
import EvIcon from '@revelotech/everestV2/EvIcon'
import { createNamespacedHelpers } from 'vuex'
import { EMPLOYERS, COMPANIES_POSITIONS } from '@/store/namespaces'

const employersHelper = createNamespacedHelpers(EMPLOYERS)
const positionsHelper = createNamespacedHelpers(COMPANIES_POSITIONS)

export default {
  name: 'PositionsCreateAuthorizations',

  components: { EvIcon },

  data () {
    return {
      langPath: __langpath,
      positionEmployers: [],
      positionsPrivate: false,
      getEmployersPromise: null
    }
  },

  computed: {
    ...employersHelper.mapGetters(['employersWithoutSelf']),
    ...positionsHelper.mapState(['positionForm']),
    privatePosition () {
      return this.positionForm.privatePosition
    },
    employersAllowedIds () {
      return this.positionForm.employersAllowedIds
    }
  },

  watch: {
    positionEmployers (newValue) {
      const attributes = {
        name: 'employersAllowedIds',
        value: newValue.map(employer => employer.value)
      }
      this.setPositionFormAttribute(attributes)
    },
    positionsPrivate (newValue) {
      const attributes = {
        name: 'privatePosition',
        value: newValue
      }
      this.setPositionFormAttribute(attributes)
    },
    privatePosition: {
      immediate: true,
      handler (value) {
        this.positionsPrivate = value
      }
    },

    employersAllowedIds: {
      immediate: true,
      async handler (value, oldValue) {
        if (JSON.stringify(value) === JSON.stringify(oldValue)) {
          return
        }

        if (typeof value !== 'object') {
          return
        }

        await this.getEmployersOnce()
        const employersList = await this.employersWithoutSelf
        this.positionEmployers = value.map((id) => {
          return employersList.find((employer) => Number(employer.id) === Number(id))
        }).map((employer) => ({
          value: employer.id,
          label: employer.name
        }))
      }
    }
  },

  async mounted () {
    await this.getEmployersOnce()
  },

  methods: {
    ...employersHelper.mapActions(['getEmployers']),
    ...positionsHelper.mapActions(['setPositionFormAttribute']),
    async findEmployers (name) {
      const employers = await this.employersWithoutSelf.filter(
        employer => (employer.name.toLowerCase()).includes(name.toLowerCase())
      )

      return employers.map((employer) => ({
        value: employer.id,
        label: employer.name
      }))
    },
    getEmployersOnce () {
      if (!this.getEmployersPromise) {
        this.getEmployersPromise = this.getEmployers()
      }
      return this.getEmployersPromise
    }
  }
}
</script>

<style lang="scss" scoped>
.private {
  &-position {
    align-items: flex-start;
    display: flex;

    &__field {
      align-items: flex-start;
      display: flex;
    }

    &__title {
      @extend %body-text2;

      margin-top: 18px;
    }

    &__description { @extend %caption; }
  }

  &-employers {
    margin-top: 4*$base;

    &__label {
      @extend %body-text2;

      align-items: center;
      display: flex;
      justify-content: space-between;

      span:last-child { @extend %caption; }
    }

    &__field { width: 100%; }
  }
}
</style>
