import { get } from '@/helpers/request'
import { companiesUrl } from '@/helpers/constants/external-urls'
import jsonApiClient from '@/helpers/jsonapi-client'

const baseUrl = `${companiesUrl}/api/v2/filters`

export default {
  async getFiltersCareers () {
    const response = await get(`${baseUrl}/careers`)
    return jsonApiClient.parse(response.data)
  }
}
