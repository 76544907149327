<template>
  <app-grid-col :md="5" :md-start="6" class="positions-create-career">
    <section class="positions-create-career__label-container">
      <label class="label">{{ $t(`${langPath}.label`) }}</label>

      <span
        class="positions-create-career__information"
        v-tooltip="tooltipOptions"
      >
        <span class="positions-create-career__information-icon">
          i
        </span>
      </span>
    </section>
    <app-searchable-select
      required
      has-others
      name="PositionsCareer"
      data-test="position-career-select"
      :items="careersPositionsOptions"
      @input="selectHandler"
      v-model="positionId"
      v-validate="'required'"
      :data-vv-as="$t(`${langPath}.label`)"
      data-vv-validate-on="input"
      :search-placeholder="$t(`${langPath}.searchPlaceholder`)"
      :select-placeholder="$t(`${langPath}.selectPlaceholder`)"
    />
  </app-grid-col>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { COMPANIES_POSITIONS } from '@/store/namespaces'
import filters from '@/repository/companies-app/filters'

const positionsHelper = createNamespacedHelpers(COMPANIES_POSITIONS)

export default {
  name: 'PositionsCreateCareer',
  data () {
    return {
      positionName: '',
      careersPositions: [],
      positionId: null,
      langPath: __langpath
    }
  },
  computed: {
    ...positionsHelper.mapState(['positionForm']),
    tooltipOptions () {
      return {
        content: this.$t(`${this.langPath}.tooltip`),
        placement: 'bottom-start',
        classes: 'positions-create-career__information-tooltip'
      }
    },
    careersPositionsOptions () {
      return this.careersPositions.map(
        careerFilterGroup => ({
          title: careerFilterGroup.name,
          items: careerFilterGroup.careers.map(
            career => ({
              label: career.name,
              value: career.id
            })
          )
        })
      )
    }
  },
  watch: {
    positionForm (newValue) {
      this.updateSelectedPosition(newValue.positionId)
    }
  },
  async mounted () {
    await this.setCarrersPositions(await filters.getFiltersCareers())
  },
  methods: {
    ...positionsHelper.mapActions(['setPositionFormAttribute']),
    selectHandler (item) {
      this.updateSelectedPosition(item)
      this.setPositionFormAttribute(
        { name: 'positionId', value: this.positionId }
      )
      this.setPositionFormAttribute(
        { name: 'positionName', value: this.positionName }
      )
    },
    updateSelectedPosition (item) {
      const position = this.getCareerPositionById(item)
      if (!position || !this.careersPositionsOptions.length) return
      this.positionId = position.id
      this.positionName = position.name
    },
    setCarrersPositions (careersPositions) {
      this.careersPositions = [
        ...careersPositions,
        {
          name: this.$t(`${this.langPath}.otherCareers`),
          careers: [{
            id: 'others',
            name: this.$t(`${this.langPath}.unlisted`)
          }]
        }
      ]
      if (this.positionForm.id) {
        this.updateSelectedPosition(this.positionForm.positionId || null)
      }
    },
    getCareerPositionById (id) {
      const positions = this.careersPositions.flatMap(
        careerPositionGroup => careerPositionGroup.careers
      )
      return positions.filter(position => position.id === String(id))[0] || {
        id: 'others',
        name: this.$t(`${this.langPath}.unlisted`)
      }
    }
  }
}
</script>

<style lang="scss">
.positions-create-career {
  &__autocomplete {
    z-index: 10;
  }

  &__label-container {
    display: flex;
    justify-content: space-between;
  }

  &__information {
    border: 1px solid var(--b-neutral-base);
    border-radius: 50%;
    color: var(--tx-neutral-base);
    cursor: pointer;
    display: block;
    font-size: 9px;
    font-weight: 700;
    height: 16px;
    padding: 3px 6px;
    width: 16px;

    &-icon {
      position: relative;
      top: -10px;
    }

    &:hover {
      background: var(--bg-neutral-base);
      border-color: var(--b-neutral-blank);
      color: var(--tx-neutral-blank);
    }
  }

  &__information-tooltip {
    margin-top: $base * 3 !important;

    .tooltip-arrow {
      display: none;
    }

    .tooltip-inner {
      background: var(--bg-neutral-blank) !important;
      border-radius: $app-border-radius !important;
      box-shadow: $shadow-16 !important;
      max-width: initial;
      padding: $base * 4 !important;
      text-align: left;
      width: 300px;
    }
  }

  .searchable-select {
    margin-top: 2 * $base;

    &__items {
      z-index: 22;
    }
  }
}
</style>
