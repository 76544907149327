<template>
  <app-grid-col
    :md="5"
    :md-start="6"
    class="positions-create-contract"
  >
    <app-input-options
      :input-type="'radio'"
      :name="'contractType'"
      :options="contractOptions"
      v-model="checkedValues"
      :required="true"
      v-validate="'required'"
      :data-vv-as="$t(`${langPath}.label`)"
      data-test="position-contract-type-input"
    >
      {{ $t(`${langPath}.label`) }}
    </app-input-options>
  </app-grid-col>
</template>
<script>
import { AppInputOptions } from '@revelotech/everest'
import { createNamespacedHelpers } from 'vuex'
import { COMPANIES_POSITIONS } from '@/store/namespaces'
import { fetchContractTypes } from '@/repository/companies-app/contract-types'

const { mapState, mapActions } = createNamespacedHelpers(COMPANIES_POSITIONS)

export default {
  name: 'PositionsCreateContractType',
  components: {
    AppInputOptions
  },
  data () {
    return {
      checkedValues: '',
      contractOptions: [],
      langPath: __langpath
    }
  },
  computed: {
    ...mapState(['positionForm'])
  },
  watch: {
    checkedValues (newValue) {
      if (newValue) {
        const attributes = {
          name: 'contractType',
          value: newValue.split(',')
        }

        this.setPositionFormAttribute(attributes)
      }
    },
    positionForm (newValue, oldValue) {
      if (newValue.contractType && oldValue.contractType) {
        this.handleCheckedValues(newValue, oldValue)
      }
    }
  },
  async created () {
    const contractTypes = await fetchContractTypes()
    const allIds = contractTypes.map((contractType) => contractType.id)
      .join(',')

    this.contractOptions = [
      ...contractTypes.map((contractType) => ({
        value: contractType.id,
        label: contractType.name
      })),
      {
        value: allIds,
        label: this.$t(`${this.langPath}.notDefined`)
      }
    ]

    this.checkedValues = this.contractOptions.filter(
      option => option.label.toLowerCase() === 'clt'
    )[0].value
  },
  methods: {
    ...mapActions(['setPositionFormAttribute']),
    handleCheckedValues (newValue, oldValue) {
      if (newValue.contractType.join() !== oldValue.contractType.join()) {
        this.checkedValues = newValue.contractType.join(',')
      }
    }
  }
}
</script>
