<template>
  <app-grid-col
    :md="8"
    :md-start="1"
    class="positions-create-header"
  >
    <h1
      class="positions-create-header__title"
      data-test="positions-create-title"
    >
      {{ $t(`${langPath}.title`, { name }) }}
    </h1>
    <h2
      class="positions-create-header__subtitle"
      data-test="positions-create-subtitle"
    >
      {{ $t(`${langPath}.subtitle`) }}
    </h2>
  </app-grid-col>
</template>

<script>
import { CURRENT_USER } from '@/store/namespaces'
import { createNamespacedHelpers } from 'vuex'

const authHelper = createNamespacedHelpers(CURRENT_USER)

export default {
  name: 'PositionsCreateHeader',
  data () {
    return {
      langPath: __langpath
    }
  },
  computed: {
    ...authHelper.mapState(['currentUser']),
    name () {
      return this.currentUser.name
    }
  }
}
</script>

<style lang="scss" scoped>
.positions-create-header {
  @include margin(top, 6);

  &__title {
    @extend %h4;
  }

  &__subtitle {
    @include margin(top, 4);
  }

  &__required-info {
    @include margin(top, 6);

    @extend %caption;
  }
}
</style>
