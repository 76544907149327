<template>
  <div class="positions-create-diversity">
    <section class="positions-create-diversity__container">
      <label
        class="label positions-create-diversity__label"
        data-test="label"
      >
        {{ $t(`${langPath}.diversityLabel`) }}
      </label>
      <div class="positions-create-diversity__input-group">
        <app-checkboxes-list
          v-model="diversitiesValues"
          name="diversities"
          class="positions-create-diversity__checkboxes-list"
          :options="diversitiesOptions"
          data-test="diversities-checkboxes-list"
          @input="setDiversities"
        />
        <ev-button
          size="small"
          color="red"
          variant="tertiary"
          data-test="open-modal-button"
          @click="toggleDiversityModal"
        >
          {{ $t(`${langPath}.diversityModalToggleButtonTitle`) }}
        </ev-button>
      </div>
    </section>
    <app-modal
      v-if="isModalOpened"
      @close="toggleDiversityModal"
      backdrop-click-close
      modal-size="box-in-the-middle"
      data-test="modal"
    >
      <article>
        <header class="positions-create-diversity__modal-header">
          <h3
            class="positions-create-diversity__modal-title"
            data-test="modal-title"
          >
            {{ $t(`${langPath}.diversityModalTitle`) }}
          </h3>
        </header>
        <p
          class="positions-create-diversity__modal-body"
          data-test="modal-body"
        >
          {{ $t(`${langPath}.diversityModalBodyText`) }}
        </p>
        <footer class="positions-create-diversity__modal-footer">
          <ev-link
            variant="tertiary"
            color="blue"
            target="_blank"
            a-href="http://www.planalto.gov.br/ccivil_03/_ato2015-2018/2015/lei/l13146.htm"
            data-test="modal-button"
          >
            {{ $t(`${langPath}.diversityModalButtonText`) }}

            <ev-icon
              name="external-link-alt"
              size="small"
              color="blue"
              class="positions-create-diversity__modal-link-icon"
            />
          </ev-link>
        </footer>
      </article>
    </app-modal>
  </div>
</template>

<script>
import EvButton from '@revelotech/everestV2/EvButton'
import EvLink from '@revelotech/everestV2/EvLink'
import EvIcon from '@revelotech/everestV2/EvIcon'
import { createNamespacedHelpers } from 'vuex'
import { COMPANIES_POSITIONS } from '@/store/namespaces'
import DIVERSITY_CATEGORIES from '@/helpers/constants/diversity-categories'
import suggestions from '@/repository/companies-app/suggestions'
import { toCamelCase } from '@/helpers/case-style'

const { mapState, mapActions } = createNamespacedHelpers(COMPANIES_POSITIONS)

export default {
  name: 'PositionsCreateDiversity',
  components: {
    EvButton,
    EvIcon,
    EvLink
  },
  data () {
    return {
      langPath: __langpath,
      diversitiesOptions: [],
      diversitiesValues: [],
      isModalOpened: false
    }
  },
  computed: {
    ...mapState(['positionForm'])
  },
  watch: {
    positionForm (newValue, oldValue) {
      if (newValue.diversities !== oldValue.diversities) {
        this.diversitiesValues = newValue.diversities
      }
    }
  },
  async created () {
    const diversities = await suggestions.getDiversities()
    const filteredOptions = diversities.filter((diversity) =>
      diversity.category === DIVERSITY_CATEGORIES.withDisability
    )

    this.diversitiesOptions = filteredOptions.map((diversity) => ({
      value: `${diversity.id}`,
      label: this.$t(`${this.langPath}.${toCamelCase(diversity.category)}`)
    }))
  },
  methods: {
    ...mapActions(['setPositionFormAttribute']),
    setDiversities (value) {
      this.diversitiesValues = value

      const attributes = {
        name: 'diversities',
        value: this.diversitiesValues
      }

      this.setPositionFormAttribute(attributes)
    },

    toggleDiversityModal () {
      this.isModalOpened = !this.isModalOpened
    }
  }
}
</script>
<style lang="scss">
.app-modal--box-in-the-middle {
  align-self: inherit;
}

.positions-create-diversity {
  @include breakpoint(md) {
    grid-column-end: 11;
  }

  grid-column-end: 4;
  grid-column-start: 1;

  &__container {
    display: flex;
    flex-flow: column;
  }

  &__input-group {
    align-items: baseline;
    display: flex;
    flex-flow: wrap;
  }

  &__modal {
    &-header {
      padding: $base*6 $base*4 $base*7 $base*4;
    }

    &-title {
      @extend %h4;
    }

    &-body {
      @extend %body-text2;

      padding: $base * 4;
    }

    &-footer {
      display: flex;
    }

    &-link-icon {
      margin-left: $base * 2;
    }
  }
}
</style>
