<template>
  <main class="positions-create">
    <app-grid>
      <app-grid-col :md="10" :md-start="2" class="positions-create-header">
        <section class="positions-create__container">
          <section class="positions-create__container-columns">
            <positions-create-header />
            <positions-create-title />
            <positions-create-career />
            <positions-create-experience-time />
            <positions-create-salary />
            <positions-create-locale />
            <positions-create-contract-type />
            <positions-create-skills />
            <positions-create-benefits />
            <positions-create-authorizations />
            <positions-create-diversity />
            <positions-create-submit />
          </section>
        </section>
      </app-grid-col>
    </app-grid>
  </main>
</template>

<script>
import PositionsCreateHeader from './components/PositionsCreateHeader'
import PositionsCreateTitle from './components/PositionsCreateTitle'
import PositionsCreateCareer from './components/PositionsCreateCareer'
import PositionsCreateExperienceTime from './components/PositionsCreateExperienceTime'
import PositionsCreateSalary from './components/PositionsCreateSalary'
import PositionsCreateLocale from './components/PositionsCreateLocale'
import PositionsCreateContractType from './components/PositionsCreateContractType'
import PositionsCreateSkills from './components/PositionsCreateSkills'
import PositionsCreateBenefits from './components/PositionsCreateBenefits'
import PositionsCreateAuthorizations from './components/PositionsCreateAuthorizations.vue'
import PositionsCreateDiversity from './components/PositionsCreateDiversity'
import PositionsCreateSubmit from './components/PositionsCreateSubmit'

import { createNamespacedHelpers } from 'vuex'
import { COMPANIES_POSITIONS } from '@/store/namespaces'

const companiesPositionsHelper = createNamespacedHelpers(COMPANIES_POSITIONS)

export default {
  name: 'PositionsCreate',
  components: {
    PositionsCreateHeader,
    PositionsCreateTitle,
    PositionsCreateCareer,
    PositionsCreateExperienceTime,
    PositionsCreateSalary,
    PositionsCreateLocale,
    PositionsCreateContractType,
    PositionsCreateSkills,
    PositionsCreateBenefits,
    PositionsCreateAuthorizations,
    PositionsCreateDiversity,
    PositionsCreateSubmit
  },

  async created () {
    this.clearPosition()

    const companiesPositionId = this.$route?.params?.positionId
    if (!companiesPositionId) return

    await this.getPosition(companiesPositionId)
  },

  methods: {
    ...companiesPositionsHelper.mapActions(['getPosition', 'clearPosition'])
  }
}
</script>

<style lang="scss" scoped>
.positions-create {
  background-color: var(--bg-neutral-blank);
  margin-top: 23*$base;
  min-height: 100%;

  @include breakpoint(md) {
    padding: 6*$base;
  }

  &__container {
    background-color: var(--bg-neutral-blank);
    padding: $base*3;

    @include breakpoint(md) {
      padding: 0 0 $base*6 0;
    }
  }

  &__container-columns {
    display: grid;
    grid-gap: 4*$base;
    grid-template-columns: 1fr 1fr;
    margin-left: 2*$base;
    margin-right: 2*$base;

    @include breakpoint(md) {
      grid-gap: 6*$base;
      grid-template-columns: repeat(10, 1fr);
      margin-left: 6*$base;
      margin-right: 6*$base;
    }
  }
}
</style>
