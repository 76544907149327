<template>
  <app-grid-col
    :md="10"
    :md-start="1"
    class="positions-create-benefits"
  >
    <label class="label">
      {{ $t(`${langPath}.label`) }}
    </label>

    <app-tags-selector
      name="benefits"
      :options="suggestedBenefits"
      v-model="benefits"
      :autocomplete-placeholder="$t(`${langPath}.placeholder`)"
      :options-title="$t(`${langPath}.helper`)"
      class="positions-create__input-group"
      :search-function="getBenefitsFunctions"
      open-scope
      :data-vv-as="$t(`${langPath}.label`)"
      data-vv-validate-on="blur"
      data-test="position-benefits-input"
      :item-name="$t(`${langPath}.tip`)"
    />
  </app-grid-col>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { COMPANIES_POSITIONS } from '@/store/namespaces'
import autocomplete from '@/repository/companies-app/autocomplete'
import suggestions from '@/repository/companies-app/suggestions'

const { mapState, mapActions } = createNamespacedHelpers(COMPANIES_POSITIONS)

export default {
  name: 'PositionsCreateBenefits',
  data () {
    return {
      langPath: __langpath,
      getBenefitsFunctions: (name) => autocomplete
        .getBenefits(name)
        .then((skills) => skills
          .map((skill) => ({ value: skill.text, label: skill.text }))
        ),
      benefits: [],
      suggestedBenefits: []
    }
  },
  computed: {
    ...mapState(['positionForm'])
  },
  watch: {
    benefits (newValue) {
      const attributes = {
        name: 'benefits',
        value: newValue
      }

      this.setPositionFormAttribute(attributes)
    },
    async positionForm (newValue, oldValue) {
      this.benefits = newValue.benefits
    }
  },
  async created () {
    this.suggestedBenefits = (await suggestions.getBenefits()).map(
      (benefit) => ({ value: benefit.text, label: benefit.text })
    )
  },
  methods: {
    ...mapActions(['setPositionFormAttribute'])
  }
}
</script>
<style lang="scss" scoped>
.positions-create-benefits {
  z-index: 10;
}
</style>
