<template>
  <app-grid-col
    :md="5"
    :md-start="6"
  >
    <div class="positions-create-salary">
      <div class="positions-create-salary__group">
        <div class="positions-create-salary__range">
          <label
            class="label"
            data-test="positions-create-salary-label"
          >
            {{ $t(`${langPath}.label`) }}
          </label>
          <span
            class="positions-create-salary__caption
            positions-create-salary__caption--gray"
            data-test="positions-create-salary-optional-flag"
          >
            {{ $t(`${langPath}.optionalFlag`) }}
          </span>
        </div>
        <div class="positions-create-salary__range">
          <app-text-input
            name="minimumSalary"
            money-mask
            v-model="minimumSalary"
            class="positions-create-salary__input"
            :placeholder="$t(`${langPath}.min`)"
            data-vv-validate-on="blur|revalidate"
            data-test="position-salary-min-input"
          />
          <app-text-input
            name="maximumSalary"
            money-mask
            v-model="maximumSalary"
            class="positions-create-salary__input"
            :placeholder="$t(`${langPath}.max`)"
            data-vv-validate-on="blur|revalidate"
            v-validate="maxMoneyValidate"
            data-test="position-salary-max-input"
          />
        </div>
      </div>
      <div
        v-if="helperIsEnabled"
        class="positions-create-salary__helper"
      >
        <app-input-helper
          class="positions-create-salary__helper-content"
          data-test="salary-average-helper"
        >
          <p
            class="positions-create-salary__average"
          >
            <strong>{{ averageSalaryMessage }}</strong>
          </p>
          <p class="positions-create-salary__companies">
            ({{ $t(`${langPath}.companiesMessage`) }})
          </p>
        </app-input-helper>
      </div>
      <app-assistive-text
        class="positions-create-salary__caption"
        data-test="position-salary-assistive-text"
        :assistive-text="$t(`${langPath}.tip`)"
      />
    </div>
  </app-grid-col>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { COMPANIES_POSITIONS } from '@/store/namespaces'

const { mapState, mapActions } = createNamespacedHelpers(COMPANIES_POSITIONS)

export default {
  name: 'PositionsCreateSalary',
  data () {
    return {
      langPath: __langpath,
      minimumSalary: '',
      maximumSalary: ''
    }
  },
  computed: {
    ...mapState(['positionForm']),
    maxMoneyValidate () {
      return {
        money_min: this.minimumSalary
      }
    },
    averageSalaryMessage () {
      const salaryAverage = `${this.positionForm.salaryAverage}`
      return this.$t(`${this.langPath}.average`, { value: salaryAverage })
    },
    helperIsEnabled () {
      return this.positionForm.salaryAverage > 0
    }
  },
  watch: {
    minimumSalary (newValue) {
      const attributes = this.setAttributes('minimumSalary', newValue)
      this.setPositionFormAttribute(attributes)
    },
    maximumSalary (newValue) {
      const attributes = this.setAttributes('maximumSalary', newValue)
      this.setPositionFormAttribute(attributes)
    },
    positionForm (newValue, _oldValue) {
      this.maximumSalary = newValue.maximumSalary
      this.minimumSalary = newValue.minimumSalary
    }
  },
  methods: {
    ...mapActions(['setPositionFormAttribute']),
    setAttributes (stateName, stateValue) {
      return {
        name: stateName,
        value: stateValue
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.positions-create-salary {
  &__helper {
    display: none;

    &-content {
      @include margin(top, 2);

      @extend %body-text2;

      align-items: flex-start;
      flex-flow: column;
      padding: $base * 2;
    }
  }

  &__range {
    display: flex;
    justify-content: space-between;
    z-index: 21;
  }

  &__group {
    &:focus-within ~ .positions-create-salary__helper {
      display: flex;
      position: absolute;
      top: 100%;
      z-index: 21;
    }

    &:focus-within .assistive-text {
      display: none;
    }
  }

  &__label {
    display: flex;
    justify-content: space-between;
  }

  &__input {
    &:first-child {
      @include margin(right, 6);
    }
  }

  &__caption {
    @extend %caption;

    &--gray {
      color: var(--tx-neutral-light-01);
    }
  }
}
</style>
